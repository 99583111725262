import ILocationData, { ILocationTypeFilterData } from '../interfaces/ILocationData'
import {
  TAddress, TGeoCoderResult, TLatLngBoundsLiteral, TLocation,
} from '../../general/types/Map'
import createContactArray from './ContactView'
import { TLocationSearchView } from '../types/TView'
import { notEmpty } from '../../general/services/TypeAssertions'
import { ILocationResponse, IPoint } from '../../general/interfaces/ILocation'

interface ICalculateDistanceBetweenTwoPointsParams {
  latFrom : number
  lngFrom : number
  latTo : number
  lngTo : number
}

// eslint for some reason says these two interfaces do not exist
// even though they're Browser native. So I copied their type definition here.
interface GeolocationCoordinates {
  readonly accuracy : number
  readonly altitude : number | null
  readonly altitudeAccuracy : number | null
  readonly heading : number | null
  readonly latitude : number
  readonly longitude : number
  readonly speed : number | null
}

interface GeolocationPosition {
  readonly coords : GeolocationCoordinates
  readonly timestamp : number
}

const makeBoundaries = (boundaries : TLatLngBoundsLiteral) => ({
  latFrom: boundaries.south,
  latTo: boundaries.north,
  lngFrom: boundaries.west,
  lngTo: boundaries.east,
})

interface ISearchLocationsParams {
  locale : string
  searchedLocation : google.maps.GeocoderResult
  initialSearch : boolean
  boundaries : TLatLngBoundsLiteral
  preFilters : ILocationTypeFilterData[]
  locationType ?: ILocationTypeFilterData
  commercialVehicles ?: boolean
  showLocationsWithoutType : boolean
}

const searchLocations = async ({
  locale, searchedLocation, initialSearch, boundaries, preFilters, locationType, commercialVehicles, showLocationsWithoutType,
} : ISearchLocationsParams)
  : Promise<ILocationResponse> => {
  const locationTypeIds : string[] = locationType
    // User selected filters
    ? [locationType.id].filter((id) => !!id) as string[]
    // Preselected filters by editor
    : preFilters.map((el) => el.id).filter((id) => !!id) as string[]

  // This filter should only be true, if the user DID NOT set a filter specifically
  // and also only if it was enabled in the CMS
  const locationsWithoutType = showLocationsWithoutType && !locationType?.id

  const locationRequest = await fetch('/api/locations/fromBoundaries', {
    method: 'POST',
    body: JSON.stringify({
      locale,
      searchedLocation: {
        lat: searchedLocation.geometry.location.lat(),
        lng: searchedLocation.geometry.location.lng(),
      },
      initialSearch,
      boundaries: makeBoundaries(boundaries),
      types: locationTypeIds,
      commercialVehicles,
      locationsWithoutType,
    }),
  })

  return locationRequest.json()
}

/**
 * Calculates the distance in km between two points based on latitudes and longitudes.
 * Based on the Haversine Formula.
 * @param latFrom
 * @param lngFrom
 * @param latTo
 * @param lngTo
 * @private
 */
const calculateDistanceBetweenTwoPoints = ({
  latFrom, lngFrom, latTo, lngTo,
} : ICalculateDistanceBetweenTwoPointsParams) : number => {
  const R = 6371 // Radius of the earth in kilometers
  const toRadians = (degrees : number) : number => degrees * (Math.PI / 180)

  const deltaLat = toRadians(latTo - latFrom)
  const deltaLon = toRadians(lngTo - lngFrom)

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)
    + Math.cos(toRadians(latFrom)) * Math.cos(toRadians(latTo))
    * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c // Distance in kilometers
}

const calculateDistance = (locationFrom : IPoint, locationTo : IPoint) : number | undefined => {
  const latTo = locationTo.lat
  const lngTo = locationTo.lng

  const latFrom = locationFrom.lat
  const lngFrom = locationFrom.lng

  // This case should never happen, as we only show the map if we actually searched for a location
  if (!latFrom || !lngFrom || !latTo || !lngTo) return undefined

  const distance = calculateDistanceBetweenTwoPoints({
    latFrom, lngFrom, latTo, lngTo,
  })

  return distance || 0
}

type SearchByInputResult = [success : boolean, location : google.maps.GeocoderResult | null]

const searchByInput = async (searchTerm ?: string, geocoder ?: google.maps.Geocoder) : Promise<SearchByInputResult> => {
  if (!searchTerm || !geocoder) {
    return [false, null]
  }

  let geocoderResponse : google.maps.GeocoderResponse | undefined
  try {
    geocoderResponse = await geocoder.geocode({ address: searchTerm })
  } catch (error) {
    return [false, null]
  }

  const searchedLocation = geocoderResponse?.results?.[0]

  if (searchedLocation) {
    return [true, searchedLocation]
  }

  return [false, null]
}

const setupAutocomplete = (searchElement : HTMLInputElement, countryCode : string, callback : Function) => {
  const autocomplete = new google.maps.places.Autocomplete(searchElement, {
    fields: ['address_components', 'adr_address', 'formatted_address'],
    strictBounds: false,
    componentRestrictions: {
      country: countryCode ?? '',
    },
  })

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    if (!place) return

    callback(place.formatted_address || place.name || '')
  })
}

interface IMapSingleAddressParams {
  location : ILocationData
  view ?: TLocationSearchView
  getUrlByPageId : Function
}

interface IMapAddressesParams {
  locations : ILocationData[]
  view ?: TLocationSearchView
  getUrlByPageId : Function
}

const mapSingleAddress = ({
  location, view, getUrlByPageId,
} : IMapSingleAddressParams) : TAddress => ({
  location: createContactArray({
    locationData: location,
    locationViews: view?.data?.tt_config_result || [],
    getUrlByPageId,
  }),
  detail: createContactArray({
    locationData: location,
    locationViews: view?.data?.tt_config_detail || [],
    getUrlByPageId,
  }),
  id: location.id,
})

const mapAddresses = ({ locations, ...base } : IMapAddressesParams) : TAddress[] => {
  // Two lines because of stupid eslint line length
  const locs = locations.map((location) => mapSingleAddress({ location, ...base }))
  return locs
}

const mapSingleMarker = (location : ILocationData, isNextLocation : boolean) : TLocation | null => {
  if (!location.data.tt_latitude || !location.data.tt_longitude) return null

  return {
    id: location.id,
    lat: location.data.tt_latitude,
    lng: location.data.tt_longitude,
    isNextLocation,
  }
}

const mapMarkers = (locations : ILocationData[], isNextLocation : boolean) : TLocation[] => {
  // Two lines because of stupid eslint line length
  const locs = locations.map((loc) => mapSingleMarker(loc, isNextLocation)).filter(notEmpty)
  return locs
}

const DEVICE_LOCATION_KEY = 'DEVICE_LOCATION'

const requestDeviceLocation = () => new Promise<GeolocationPosition>((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      resolve(position)
    },
    (err) => {
      reject(err)
    },
    {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    },
  )
})

const createGeocoderResultMock = (lat : number, lng : number) => ({
  types: ['street_address'],
  address_components: [DEVICE_LOCATION_KEY],
  geometry: {
    location: {
      lat: () => lat,
      lng: () => lng,
    },
  },
  // we have to typecast because TGeoCoderResult has so many things which we don't use and I don't want to fake them
} as unknown as TGeoCoderResult)

export {
  DEVICE_LOCATION_KEY,
  requestDeviceLocation,
  createGeocoderResultMock,
  searchLocations,
  calculateDistance,
  searchByInput,
  setupAutocomplete,
  mapAddresses,
  mapMarkers,
}
