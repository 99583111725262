
import Vue from 'vue'
import {
  Component, Emit, Prop, Watch,
} from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import {
  Cluster, MarkerClusterer, SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer'
import BaseButton from '../base/BaseButton.vue'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'
import clusterIcon from '../../static/assets/svg/ClusterIcon.svg'
import locationPoint from '../../static/assets/svg/LocationPoint.svg'
import locationPointActive from '../../static/assets/svg/LocationPointActive.svg'
import tailwindConfig from '../../tailwind.config.js'
import {
  TGeoCoderResult, TLatLng, TLatLngBoundsLiteral, TLocation, TMarker,
} from '../../shared/general/types/Map'
import throttle from '../../shared/general/services/Throttle'

type MapsEventListener = google.maps.MapsEventListener

@Component({
  name: 'Map',
  components: {
    BaseButton,
  },
})
export default class Map extends Vue {
  @Prop({ default: false }) showSearchInArea! : boolean

  @Prop({ default: false }) showCloseButton! : boolean

  @Prop({ default: false }) showControls! : boolean

  @Prop({ default: false }) useDrag! : boolean

  @Prop({ required: true }) mapOpen! : boolean

  @Prop({ default: () => [] }) locations! : TLocation[]

  @Prop() searchedLocation ?: TGeoCoderResult

  @Prop() currentlySelected! : string

  @Prop({ default: 50 }) mapBoundsPadding! : number

  $refs! : {
    map ?: HTMLElement
  }

  private previousSearchedLocation ?: TGeoCoderResult

  private map! : google.maps.Map

  private markerClusterer : MarkerClusterer = {} as MarkerClusterer

  private markers : TMarker[] = []

  private currentlySelectedMarker ?: TMarker

  private searchedLocationMarker ?: TMarker

  private markerMap : Record<string, TMarker> = {}

  private maxZIndex = 1000

  // AdvancedMarkerElement is a constructor which is asynchronously imported from google.maps.importLibrary
  private AdvancedMarkerElement! : typeof google.maps.marker.AdvancedMarkerElement

  private areaTypes = ['locality', 'postal_code', 'country', 'administrative_area_level_1', 'administrative_area_level_2']

  private specificTypes = ['premise', 'street_address', 'route', 'establishment', 'point_of_interest']

  private isSpecificPoint = () => this.searchedLocation?.types.some((type) => this.specificTypes.includes(type)) ?? false

  private isArea = () => this.searchedLocation?.types.some((type) => this.areaTypes.includes(type)) ?? false

  // Will only call dragMap at most once a second
  // Important when we drag the map so we don't get the markers on every event fire
  private throttledUseDrag = throttle(this.dragMap, 1000)

  // We need to store it here to remove it properly again
  private dragListeners : MapsEventListener[] = []

  mounted () {
    this.initMap()
  }

  private async initMap () {
    if (!this.$refs.map) return

    const { Map: GoogleMap } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary
    this.AdvancedMarkerElement = AdvancedMarkerElement

    const center = this.getCenter()

    const mapOptions = {
      center,
      mapId: 'SOME_NON_EXISTANT_MAP_ID', // '8cc8554222c24e39',
      zoom: 15,
      disableDefaultUI: true,
      fullscreenControl: false,
      zoomControl: this.showControls,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      gestureHandling: 'greedy',
    }

    this.map = new GoogleMap(
      this.$refs.map,
      mapOptions,
    )

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      // focus map once (initial search)
      this.focusMap()

      if (this.searchedLocation?.geometry.bounds && this.isArea()) {
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
          google.maps.event.addListenerOnce(this.map, 'idle', () => {
            this.ready()
          })
        })
        this.map.fitBounds(this.searchedLocation.geometry.bounds, this.mapBoundsPadding)
      } else {
        this.ready()
      }
    })

    this.onUseDragChanged()
  }

  private getCenter () : TLatLng {
    if (this.searchedLocation) {
      return new google.maps.LatLng(this.searchedLocation.geometry.location.lat(), this.searchedLocation.geometry.location.lng())
    }

    // Placeholder. Should be for the single point of a DirectionMap later
    return new google.maps.LatLng(49.1229114, 8.5085979)
  }

  private createMarkerIcon (url : string, label ?: string) : HTMLElement {
    if (!label) {
      const img = document.createElement('img')
      img.src = url
      return img
    }

    const container = document.createElement('div')
    const span = document.createElement('span')
    const img = document.createElement('img')
    img.src = url
    span.innerText = label

    container.appendChild(img)
    container.appendChild(span)

    container.classList.add('cluster')

    return container
  }

  private createMarkers () : void {
    // Remove all previous markers
    // Can probably later be optimized by storing into a map and only add/remove what changed
    this.markers.forEach((marker) => { marker.map = null })

    this.markers = this.locations.map((position : TLocation) => {
      const markerOptions = {
        map: this.map,
        position,
        content: this.createMarkerIcon(locationPoint),
      }

      const marker = new this.AdvancedMarkerElement(markerOptions)

      this.markerMap[position.id] = marker

      // markers can only be keyboard focusable when they have click listeners
      marker.addListener('click', () => {
        if (this.currentlySelectedMarker) {
          this.currentlySelectedMarker.content = this.createMarkerIcon(locationPoint)
          this.currentlySelectedMarker.zIndex = this.maxZIndex - 1
        }

        marker.content = this.createMarkerIcon(locationPointActive)
        marker.zIndex = this.maxZIndex

        this.map.panTo(position)

        this.currentlySelectedMarker = marker
        this.markerClick(position.id)
      })

      if (position.id === this.currentlySelected) {
        marker.content = this.createMarkerIcon(locationPointActive)
        marker.zIndex = this.maxZIndex
        this.currentlySelectedMarker = marker
      }

      return marker
    })

    this.markerClusterer.clearMarkers?.()
    this.markerClusterer = new MarkerClusterer({
      markers: [...this.markers],
      map: this.map,
      algorithm: new SuperClusterAlgorithm({ maxZoom: 15, minPoints: 2, radius: 200 }),
      renderer: {
        render: ({ count, position }) => new this.AdvancedMarkerElement({
          position,
          zIndex: this.maxZIndex + count,
          content: this.createMarkerIcon(clusterIcon, String(count)),
          title: String(count),
        }),
      },
    })

    this.createSearchedLocationMarker()
  }

  private createSearchedLocationMarker () : void {
    if (!this.searchedLocation) return

    // Remove old markers and borders
    if (this.searchedLocationMarker) this.searchedLocationMarker.map = null

    // Add searched location pin
    if (this.isSpecificPoint()) {
      const searchedLocationPin = new google.maps.marker.PinElement({
        background: tailwindConfig.theme.colors.red[400],
        borderColor: tailwindConfig.theme.colors.red[700],
        glyphColor: tailwindConfig.theme.colors.red[700],
      })

      this.searchedLocationMarker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        position: this.getCenter(),
        content: searchedLocationPin.element,
      })
    }
  }

  private get reloadLabel () : string | RichTextElement[] {
    return globalLabel('location_refresh_label')
  }

  private get closeMapLabel () : string {
    return globalLabelAsString('location_search_map_close')
  }

  public panToId (id : string) : void {
    const marker = this.markerMap[id]
    if (!marker) return
    this.map.panTo(marker.position!)
  }

  private getBounds () : TLatLngBoundsLiteral {
    return this.map.getBounds()!.toJSON()
  }

  private getClusterOfMarker (marker : TMarker) : Cluster | undefined {
    // clusters is a protected field. but js can still access it without problems.
    // typescrit would annoy us though.
    const clusterer = this.markerClusterer as unknown as { clusters : Cluster[] }
    return clusterer.clusters.find((cluster : any) => cluster.markers.length > 1 && cluster.markers.includes(marker))
  }

  private setCurrentlySelectedMarkerToSelectedId () {
    this.currentlySelectedMarker = this.markerMap[this.currentlySelected]
    this.currentlySelectedMarker.content = this.createMarkerIcon(locationPointActive)
    this.currentlySelectedMarker.zIndex = this.maxZIndex
    this.panToId(this.currentlySelectedMarker.id)

    // If we're in a cluster, get all markers in it and set bounds from the available markers
    const cluster = this.getClusterOfMarker(this.currentlySelectedMarker)
    if (cluster) {
      const bounds = new google.maps.LatLngBounds()
      cluster.markers?.forEach((marker) => bounds.extend((marker as TMarker).position!))
      this.map.fitBounds(bounds, this.mapBoundsPadding)
    }
  }

  private panToSearchedLocation () {
    if (this.searchedLocation?.geometry.bounds && this.isArea()) {
      this.map.fitBounds(this.searchedLocation.geometry.bounds, this.mapBoundsPadding)
    }

    if (this.searchedLocation && this.isSpecificPoint()) {
      this.map.setZoom(15)
      this.map.panTo(this.getCenter())
    }
  }

  private focusMap () : void {
    if (!this.$refs.map) return
    this.$refs.map.focus()
  }

  @Watch('mapOpen')
  private onMapOpen () : void {
    if (!this.mapOpen) return
    this.focusMap()
  }

  @Watch('currentlySelected')
  private onCurrentlySelectedChanged () : void {
    if (this.currentlySelectedMarker) {
      this.currentlySelectedMarker.content = this.createMarkerIcon(locationPoint)
      this.currentlySelectedMarker.zIndex = this.maxZIndex - 1
    }

    this.setCurrentlySelectedMarkerToSelectedId()
  }

  // Not only dragging the map, but also zooming the map
  @Watch('useDrag')
  private onUseDragChanged () : void {
    const setup = (event : string) => {
      if (this.useDrag) {
        this.dragListeners.push(google.maps.event.addListener(this.map, event, this.throttledUseDrag))
      }

      if (!this.useDrag) {
        this.dragListeners.forEach((listener) => listener.remove())
        this.dragListeners = []
      }
    }

    setup('drag')
    setup('zoom_changed')
  }

  @Watch('searchedLocation')
  private onSearchedLocationChanged () : void {
    if (JSON.stringify(this.previousSearchedLocation?.address_components)
        === JSON.stringify(this.searchedLocation?.address_components)
    ) {
      return
    }

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.searchedLocationChanged()
      })
    })

    this.createSearchedLocationMarker()
    this.panToSearchedLocation()
  }

  @Watch('locations')
  private onLocationsChanged () : void {
    this.createMarkers()
    // If we only get one location, this in nearly all cases meant we searched for a location where there's no result
    // near the searched location and the backend sent us the absolute nearest one.
    // To show the nearest one, we take it's lat/lng and fit the bounds to the map to show that location.
    // Because of changed bounds we then probably have more locations to show, so we reload the markers once more to show all of them.
    // We save the last singleLocation and compare IDs, so we do not get into an infinite loop of reloading markers
    // if there is actually really only one location to show
    const [singleLocation] = this.locations
    if (this.locations.length === 1 && singleLocation.isNextLocation) {
      const bounds = this.map.getBounds()!
      bounds.extend({ lat: singleLocation.lat, lng: singleLocation.lng })
      this.map.fitBounds(bounds, this.mapBoundsPadding)
      this.reloadMarkers()
    }
  }

  @Emit('reload-markers')
  private reloadMarkers () : TLatLngBoundsLiteral {
    return this.map.getBounds()!.toJSON()
  }

  @Emit('marker-click')
  private markerClick (id : string) : string {
    return id
  }

  @Emit('ready')
  private ready () : TLatLngBoundsLiteral {
    return this.getBounds()
  }

  @Emit('searched-location-changed')
  private searchedLocationChanged () : TLatLngBoundsLiteral {
    return this.getBounds()
  }

  @Emit('drag')
  private dragMap () {
    return this.getBounds()
  }

  @Emit('close')
  private close () : boolean {
    return true
  }
}
