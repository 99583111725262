
import {
  Component, Prop, Vue,
} from 'nuxt-property-decorator'
import { FSXARichText } from 'fsxa-pattern-library'
import { Option, RichTextElement } from 'fsxa-api'
import IContactElement, { TContactElementDataType } from '../../shared/general/interfaces/IContactElement'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import IContactLocation from '../../shared/fsxa/interfaces/IContactLocation'
import IImage from '../../shared/general/interfaces/IImage'
import { ILink } from '../../shared/general/interfaces/ILink'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'
import { TLocationCta } from '../../shared/general/types/TLocationCta'
import IContactCta from '../../shared/general/interfaces/IContactCta'
import { TIconButtonLabel } from '../../shared/general/types/TIconButtonLabel'
import { notEmpty } from '../../shared/general/services/TypeAssertions'

@Component({
  name: 'ContactElements',
  components: {
    FSXARichText,
    ContactElementLocation: () => import('./ContactElementLocation.vue'),
    BasePicture: () => import('../base/BasePicture.vue'),
    BaseLink: () => import('../base/BaseLink.vue'),
    BaseIcon: () => import('../base/BaseIcon.vue'),
    BaseList: () => import('../base/BaseList.vue'),
    IconButtonLabel: () => import('../IconButtonLabel.vue'),
    GlobalLabelWrapper: () => import('../GlobalLabelWrapper.vue'),
  },
})
export default class ContactElements extends Vue {
  @Prop({ default: () => [] }) contentElements! : IContactElement[]

  @Prop({ default: 'standard' }) size! : 'small' | 'standard'

  @Prop({ default: true }) showCurrentlyOpen! : boolean

  @Prop({ default: false }) linkAsButton! : boolean

  @Prop({ default: EButtonVariant.Default }) buttonVariant! : EButtonVariant

  @Prop() locationCta ?: TLocationCta

  private iconName = {
    phone: 'phone-flip',
    email: 'envelope',
  }

  private validTypes : Record<string, string> = {
    certificate: 'linkWhgCertificates',
    directions: 'linkRoutePlanner',
    website: 'linkWebsite',
    appointment: 'linkOnlineAppointment',
  }

  private withoutIcon (contentElement : IContactElement) : boolean {
    const withoutIcon = (contentElement?.data as ILink)?.withoutIcon
    return withoutIcon === undefined
      ? true
      : withoutIcon
  }

  private get filteredElements () : IContactElement[] {
    return this.contentElements.filter((element) => element.type === 'distance' || !!element.data)
  }

  private getDistanceGlobalLabel (distance : number) : string {
    const distanceLabel = globalLabelAsString('label_distance')

    const distanceUnit = globalLabelAsString('label_distance_unit')
    const distanceFixed = distance.toFixed(1)
    return `${distanceLabel}: ${distanceFixed === '0.0' ? '0' : distanceFixed} ${distanceUnit}`
  }

  private getImageMargin (index : number, contentElement : IContactElement) {
    const margin : string[] = []
    const marginBottom = this.getMarginBottom(index)

    if (this.filteredElements.length > 1) {
      margin.push(marginBottom)
    }

    if (index === 0 && contentElement.col === 'left') {
      margin.push('-mt-6')
    }

    return margin.join(' ')
  }

  private getLinkStyle (index : number, linkType : string) : string {
    const margin = this.getMarginBottom(index)
    if (linkType === 'link') return margin
    return linkType === 'social' ? `h-5 ${margin}` : `${margin} w-full pb-2`
  }

  private getMarginBottom (index : number) : string {
    const value = this.filteredElements[index].bottomSpacing
    switch (value) {
      case '24px':
        return 'mb-6'
      case '16px':
        return 'mb-4'
      case '8px':
        return 'mb-2'
      default:
        return ''
    }
  }

  private getHref (type : string, contentElement : IContactElement) : string {
    return type === 'email' ? `mailto:${contentElement.data}` : `tel:${contentElement.data}`
  }

  private asLocationInformation (data : TContactElementDataType) : IContactLocation {
    return data as IContactLocation
  }

  private asString (data : TContactElementDataType) : string {
    return data as string
  }

  private asImage (data : TContactElementDataType) : IImage {
    return data as IImage
  }

  private asListData (data : TContactElementDataType) : Option[] {
    return Array.isArray(data) ? data as Option[] : []
  }

  private get activityLabel () : string | RichTextElement[] {
    return globalLabel('field_of_activity_label')
  }

  private mapToIconButtonLabel (type : string) : TIconButtonLabel | undefined {
    const key = this.locationCta?.[type]?.key || ''
    if (!key.length) return undefined
    // The filtered one has a special payload as we set it up
    const item : IContactCta | undefined = this.filteredElements
      .find((element : IContactElement) => element.type === this.validTypes[key]) as IContactCta | undefined

    if (!item?.data) return undefined

    const data = Array.isArray(item.data) ? item.data[0] : item.data
    if (!data) return undefined

    return {
      label: data.globalLabelKey ? globalLabelAsString(data.globalLabelKey) : data.label,
      iconName: data.iconName,
      iconType: data.iconType,
      url: data.url,
      variant: type === 'primary' ? 'default' : 'outline',
    }
  }

  private get contactElements () : TIconButtonLabel[] {
    const types : Array<keyof TLocationCta> = ['primary', 'secondary', 'tertiary']
    return types.map((type) => this.mapToIconButtonLabel(type)).filter(notEmpty)
  }
}
